<template>
  <v-card outlined>
    <v-card-title
      class="font-weight-bold title"
      v-text="title"
      v-if="!hideTitle"
    />
    <v-divider v-if="!hideTitle" />

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-card-text>
        <v-container fluid grid-list-xl pa-0 pa-md-2>
          <template v-if="loading">
            <Loading :loading="loading" />
          </template>
          <template v-else>
            <slot name="content"></slot>
          </template>
        </v-container>
      </v-card-text>

      <!-- CARD FOOTER -->
      <v-divider v-if="!(hideCancel && hideSubmit)" />
      <v-card-actions
        v-if="!(hideCancel && hideSubmit)"
        class="d-flex justify-space-between justify-sm-end"
      >
        <slot name="footer-custom-button-before"></slot>
        <v-btn v-if="!hideCancel" class="mx-2" @click="handleClose">{{
          $t(textCancel)
        }}</v-btn>
        <v-btn
          class="mx-2"
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="invalid || loading"
          @click="$emit('submit', $event)"
          v-if="!hideSubmit"
        >
          {{ $t(textSave) }}
          <v-icon class="ml-2" v-text="iconSave" />
        </v-btn>
        <slot name="footer-custom-button-after"></slot>
      </v-card-actions>
    </ValidationObserver>
  </v-card>
</template>

<script>
import Loading from '../commons/Loading'
export default {
  name: 'BaseModal',
  components: { Loading },
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    textCancel: {
      type: String,
      default: 'btn_cancel'
    },
    textSave: {
      type: String,
      default: 'btn_save'
    },
    iconSave: {
      type: String,
      default: 'mdi-send'
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    hideSubmit: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('close', true)
    }
  }
}
</script>
<style lang="sass">
.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card
    min-height: 100vh
    height: auto
</style>
