<template>
  <v-app class="bg-login">
    <loading :loading="loading" />
    <v-main>
      <v-container class="login-container" fluid>
        <v-row align-content="center" class="login-container__row">
          <v-col
            class="login-container__col hidden-sm-and-down"
            :class="handlePublication() ? ' left__publication' : 'left__av'"
            :style="!loading ? background : ''"
            cols="12"
            md="6"
          >
            <div v-if="handlePublication()" class="publication__text">
              <div v-html="publication.content" />
            </div>
            <div v-else class="left__text">
              <div v-if="!broker.id  && !loading">
                <p class="uppercase">{{ $t('login_knowledge') }}</p>
                <p class="uppercase">{{ $t('login_&_reliability') }}</p>
              </div>
            </div>
          </v-col>
          <!-- FORM LOGIN -->
          <v-col v-if="!loading" class="login-container__col right" cols="12" md="6">
            <v-row>
              <v-col cols="10" offset="1">
                <div
                  class="logo-container d-flex flex-column justify-center align-center"
                >
                  <img
                    :src="logo"
                    :alt="altLogo"
                    :class="{'logo-container__image': !broker.id}"
                  />
                  <p class="logo-container__text">
                    {{ $t(title) }}
                  </p>
                </div>
              </v-col>
              <v-col cols="10" offset="1">
                <v-card rounded elevation="0">
                  <v-card-text v-if="questionSelectedQuantity == 0">
                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="handleSubmitCreateQuestionUser">
                        <v-row
                          class="px-0 px-md-5"
                          v-for="(question, i) in userQuestions"
                          :key="i"
                        >
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              :name="$t('login_security_questions')"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-select
                                v-model="question.id"
                                item-text="question"
                                item-value="id"
                                :items="items"
                                :menu-props="{ maxHeight: '400' }"
                                :label="$t('login_security_questions')"
                                persistent-hint
                                clearable
                                :error-messages="errors[0]"
                              >
                                <template #item="{item}">
                                  {{ $t(item.question) }}
                                </template>
                                <template #selection="{item}">
                                  <div>
                                    {{ $t(item.question).slice(0, 15) + '...' }}
                                  </div>
                                </template>
                              </v-select>
                            </ValidationProvider>
                          </v-col>
                          <v-col cols="12" md="6">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                              :name="$t('login_security_answer')"
                            >
                              <v-text-field
                                placeholder="Responda la pregunta brevemente"
                                v-model="question.answer"
                                :label="$t('login_security_answer')"
                                :error-messages="errors[0]"
                                :type="show ? 'text' : 'password'"
                                @click:append="show = !show"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-space-between flex-row align-baseline"
                          >
                            <v-spacer></v-spacer>
                            <language-menu class="mx-auto" />
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-btn
                            color="primary"
                            type="submit"
                            :class="{
                              'login-button': !broker.id
                            }"
                            rounded
                            :loading="loading"
                            :disabled="invalid"
                            >{{ $t(saveText) }}</v-btn
                          >
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </v-card-text>
                  <v-card-text v-else>
                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="handleSubmitValidateQuestionUser">
                        <v-row
                          class="px-0 px-md-5"
                          v-for="(question, i) in userQuestions"
                          :key="i"
                        >
                          <v-col cols="12" md="12">
                            <label for="">{{ $t(question.question) }}</label>
                            <ValidationProvider
                              :name="$t('login_security_answer')"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="$t('login_security_answer')"
                                :placeholder="
                                  $t('login_security_answer_placeholder')
                                "
                                v-model="question.answer"
                                :error-messages="errors[0]"
                                autocapitalize="none"
                                :type="show ? 'text' : 'password'"
                                @click:append="show = !show"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              />
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                            class="d-flex justify-space-between flex-row align-baseline"
                          >
                            <v-spacer></v-spacer>
                            <language-menu class="mx-auto" />
                          </v-col>
                        </v-row>
                        <v-row
                          class="d-flex flex-column-reverse flex-md-row justify-center align-center"
                          dense
                        >
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              class="btn-cancel my-2"
                              outlined
                              color="primary"
                              :to="{ name: 'Login', query: { broker: $route.query.broker } }"
                              rounded
                              >{{ $t('btn_cancel') }}</v-btn
                            >
                          </v-col>
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              color="primary"
                              type="submit"
                              class="my-2"
                              :class="{
                                'login-button': !broker.id
                              }"
                              rounded
                              :loading="loading"
                              :disabled="invalid"
                              >{{ $t(saveText) }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UserService from '@/services/user'
import SettingsService from '@/services/setting'
import QuestionsService from '@/services/question'
import QuestionUserService from '@/services/questionUser'
import BrokerService from '@/services/broker'
import AccountService from '@/services/account'
import PublicSettingService from '@/services/publicSetting'
import LanguageMenu from '@/components/commons/LanguageMenu'
import Loading from '@/components/commons/Loading'
import TwoFactorModal from '@/components/commons/TwoFactorModal.vue'

export default {
  components:{ LanguageMenu, Loading },
  data() {
    return {
      loading: false,
      show: false,
      quantityQuestions: null,
      userQuestions: [],
      questions: [],
      questionSelectedQuantity: null,
      saveText: 'btn_save',
      title: 'login_security_questions_title',
      questionSelecteds: [],
      broker: {},
      publication: {
        date: null,
        content: null
      },
      publicSettings: [],
      whiteBranding: null
    }
  },
  computed: {
    ...mapState('auth', ['user', 'tempUser']),
    ...mapState('account', ['accounts']),
    ...mapState('language', ['idiom']),

    currentDate() {
      return this.$date().format('YYYY-MM-DD')
    },

    items() {
      return this.questions.map(item => {
        return {
          ...item,
          disabled: this.userQuestions.findIndex(el => el.id === item.id) > -1
        }
      })
    },

    logo() {
      return this.broker.id ? `data:image/png;base64,${this.broker.img_desktop}` : require('@/assets/AV_002_logo.jpg')
    },

    altLogo() {
      return this.broker.id ? `${this.broker.name} Logo` : 'AV Agil Logo'
    },

    background() {
      return this.broker.id && this.broker.img_background ? `background-image: url('${this.broker.img_background}')` : `background-image: url('${require('@/assets/AV_001_inicio_sinletras.jpg')}')`
    }
  },
  watch: {
    idiom(newVal) {
      const content = this.publicSettings.find(
        item => item.key == `publications_content_${newVal.code}`
        )?.value
      this.$set(this.publication, 'content', content ?? null) 
    }
  },
  async created() {
    this.loading = true
    try {
      await this.getSettings()
      await this.verifyQuestionsExist()
      await this.populateUserQuestions()
      await this.getPublicSettings()

      const broker = this.$route.query.broker
      if (broker && this.whiteBranding) {
        await this.getBrokerInfo(broker)
      }
    } catch (e) {
      if (e.response.status !== 401) this.$dialog.notify.error(e)
    } finally {
      this.loading = false
    }
  },
  methods: {  
    ...mapActions('account', [
      'setAccounts',
      'setAccount',
      'setAuthorityAccounts'
    ]),

    ...mapActions('auth', [
      'setCurrentUser',
      'setTempUser',
    ]),

    ...mapActions('setting', [
      'setWhiteBranding'
    ]),

    async handleSubmitCreateQuestionUser() {
      this.loading = true
      try {
        await this.saveAnswers(this.userQuestions)
        if (this.validateTwoFactor() && !this.tempUser.twofactor_habilitated) {
          await this.handleTwoFactorModal(false)
        }
        await this.setUserData()
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e)
      } finally {
        this.loading = false
      }
    },
    async handleSubmitValidateQuestionUser() {
      this.loading = true
      try {
        await QuestionUserService.getUserAnswers(this.userQuestions)
        if (this.validateTwoFactor() && !this.tempUser.twofactor_habilitated) {
          await this.handleTwoFactorModal(false)
        }
        await this.setUserData()
      } catch (e) {
        this.$dialog.notify.error(e)
      } finally {
        this.loading = false
      }
    },
    async handleTwoFactorModal(twoFactor) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '80%',
        transition: 'dialog-bottom-transition',
        persistent: false,
        hideOverlay: false,
        twoFactor: twoFactor,
        hideSubmit: true,
        showClose: twoFactor
      }

      await this.$dialog.showAndWait(TwoFactorModal, params)
    },
    async getSettings() {
      const { data } = await SettingsService.fetch({
        'filter[key]': 'quantity_questions'
      })
      this.quantityQuestions = data.data[0].value
    },
    async populateQuestions() {
      const { data } = await QuestionsService.fetch({
        'filter[active]': 1,
        limit: 999
      })
      this.questions = data.data
    },
    async populateQuestionsEmptys(questions) {
      const { data } = await QuestionUserService.getQuestionsEmptys(questions)
      this.questions = data
    },
    async saveAnswers(questions) {
      await QuestionUserService.save(questions)
    },
    async verifyQuestionsExist() {
      const data = await UserService.find(this.tempUser.id, {
        include: 'questions'
      })
      this.questionSelectedQuantity = data.questions.length

      if (this.questionSelectedQuantity > 0) {
        if (this.questionSelectedQuantity < this.quantityQuestions) {
          this.title = 'login_security_questions_title'
          this.saveText = 'save'
          this.questionSelecteds = data.questions
          this.questionSelectedQuantity = 0
          await this.populateQuestionsEmptys(this.questionSelecteds)
        } else {
          this.title = 'login_security_questions_title_alt'
          this.saveText = 'login_login'
          this.questions = data.questions
          for (let i = 0; i < this.questions.length; i++) {
            let indiceAleatorio = Math.floor(Math.random() * (i + 1))
            let temporal = this.questions[i]
            this.questions[i] = this.questions[indiceAleatorio]
            this.questions[indiceAleatorio] = temporal
          }
        }
      } else {
        await this.populateQuestions()
      }
    },
    populateUserQuestions() {
      let quantity = 0

      if (this.quantityQuestions <= this.questionSelectedQuantity) {
        const rand = Math.floor((Math.random() * (this.userQuestions.length - 0)) + 0)
        return this.userQuestions = [{
          question_id: this.questions[rand].id,
          question: this.questions[rand].question,
          answer: '',
          user_id: this.tempUser.id
        }]
      }

      if (this.quantityQuestions > this.questionSelectedQuantity) {
        quantity = this.quantityQuestions - this.questionSelecteds.length
      } else {
        quantity =
          this.quantityQuestions > this.questions.length
            ? this.questions.length
            : this.quantityQuestions
      }

      for (let i = 0; i < quantity; i++) {
        this.userQuestions.push({
          question_id: this.questions[i].id,
          question: this.questions[i].question,
          answer: '',
          user_id: this.tempUser.id
        })
      }
    },

    async populateAccounts() {
      this.setAuthorityAccounts([])
      let accounts = []
      let unavailableAccounts = []
      let params = {}

      let account = this.user.avobjects.find(
        obj => obj.typeCode === 'accountCode'
      )
      let authority = this.user.avobjects.find(
        obj => obj.typeCode === 'authorityCode'
      )
      let group = this.user.avobjects.find(obj => obj.typeCode === 'groupCode')

      if (account) {
        params['accountCode'] = account?.code
      }

      if (group) {
        params['groupCode'] = group?.code
      }

      if (account || group) {
        const { data } = await AccountService.fetch(params)
        accounts = data
      }

      this.setAccounts(accounts)
      this.setAccount(null)

      if (authority) {
        const authorityAccounts = await AccountService.fetch({
          authorityCode: authority?.code
        })

        unavailableAccounts = authorityAccounts.data.filter(function(array_el) {
          return (
            accounts.filter(function(anotherOne_el) {
              return anotherOne_el.code == array_el.code
            }).length == 0
          )
        })

        this.setAuthorityAccounts(unavailableAccounts)
        this.setAccounts(accounts.concat(unavailableAccounts))
      }

      if (accounts.length === 1 && unavailableAccounts.length === 0) {
        this.setAccount(accounts[0])
      }
    },

    async setUserData() {
      this.setCurrentUser(this.tempUser)
      this.setTempUser(null)

      this.setWhiteBranding(this.whiteBranding)

      await this.populateAccounts()

      const routeName = this.accounts.length > 1 ? 'AccountSelect' : 'Home'
      this.$router.push({ name: routeName })
    },

    async getBrokerInfo(code) {
      this.loading = true
      try {
        const { data } = await BrokerService.getBrokerInfo(code)
        this.broker = data
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false
      }
    },

    async getPublicSettings() {
      const { data } = await PublicSettingService.fetch({})
      this.publication.date = data.find(item => item.key == 'publications_date')?.value
      this.publication.content = data.find(item => item.key == `publications_content_${this.idiom.code}`)?.value
      this.whiteBranding = data.find(item => item.key == 'white_branding')?.value

      this.publicSettings = data
    },

    handlePublication() {
      return this.handlePublicationDate() 
        && this.handlePublicationContent() 
        && !this.broker.id 
        && !this.isLoading
    },

    handlePublicationDate() {
      return (this.publication.date !== '' && this.publication.date !== null) && this.publication.date >= this.currentDate 
    },

    handlePublicationContent() {
      return this.publication.content !== null && this.publication.content !== ''
    },
    validateTwoFactor() {
      const portalAccess = this.publicSettings.find(item => item.key ==='2fa_portal_access')
      if (portalAccess) return portalAccess.value

      return false
    }
  }
}
</script>

<style scoped>
.theme--light.v-application.bg-login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background: rgb(255, 255, 255);
  background-size: cover !important;
  background-repeat: no-repeat;
  /* font-family: Lato; */
}
.login-container {
  min-height: 100vh;
}
.login-container__row {
  min-height: 100vh;
}
.login-container__col {
  min-height: 100vh;
}
.left__av {
  background-size: cover;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100vh;
}

.left__publication {
  background-image: url('../../assets/AV_004_inicio_portal_clientes_sinletra.jpg');
  background-size: cover;
  display: flex;
  align-items: center;
  height: 100vh;
  padding: 50px;
}
.left__text {
  width: 50px;
  margin-left: -200px;
  text-align: left;
  font: normal normal 300 44px/52px Lato !important;
  letter-spacing: -1.1px !important;
  color: #0294de !important;
  opacity: 1;
}

.publication__text {
  color: white;
  width: 100vh;
}

.right {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.form {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  justify-content: space-between;
}
.links a {
  font: normal normal normal 16px/27px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
  text-decoration: none;
}
.login-button {
  min-width: 140px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.btn-cancel {
  min-width: 140px !important;
}
.logo-container__text {
  margin-top: 20px;
  text-align: center;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}
.logo-container__image {
  width: 85%;
}
.bg-login {
  font: normal normal normal 16px/27px Lato;
}
.form label {
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0px;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 979px) {
  
  .logo-container__text {
    font: normal normal normal 16px/18px Lato;
  }
  .logo-container__text_two {
    font: normal normal normal 16px/18px Lato;
  }
  .text-left {
    font: normal normal normal 12px/27px Lato;
  }
}
</style>