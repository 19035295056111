<template>
  <base-form-modal
    title="Two Factor Authenticator"
    :loading="loading"
    @submit="handleSubmit(true)"
    @close="handleSubmit(false)"
    :textSave="$t('btn_save')"
    :hideCancel="hideCancel"
    :hideSubmit="hideSubmit"
    :hideTitle="hideTitle"
  >
    <template #content>
      <v-container v-if="twoFactor">
        <TwoFactorConfirm @verify="handleSubmit" :twoFactor="twoFactor" @reset="handleReset" />
      </v-container>
      <v-container class="pa-0 px-md-6" v-if="!twoFactor">
        <TwoFactorRegister @verify="handleSubmit" :twoFactor="twoFactor" :reset="reset" />
      </v-container>
    </template>
  </base-form-modal>
</template>

<script>
import TwoFactorRegister from '@/views/2fa/Index.vue'
import TwoFactorConfirm from '@/views/2fa/partials/Confirm.vue'
import BaseFormModal from '@/components/commons/BaseFormModal'
import { mapState } from 'vuex'

export default {
  components: { BaseFormModal, TwoFactorRegister, TwoFactorConfirm },
  props: {
    twoFactor: {type: Boolean},
    hideCancel: {
      type: Boolean,
      default: true
    },
    hideSubmit: {type: Boolean},
    hideTitle: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean, 
      default: true
    },
    reset: {
      type: Boolean, 
      default: false
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('language', ['languages', 'idiom'])
  },
  methods: {
    handleSubmit(val) {
      this.$emit('submit', val)
    },
    handleReset() {
      this.$emit('submit', 'reset')
    }
  }
}
</script>

<style></style>
