var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"bg-login"},[_c('loading',{attrs:{"loading":_vm.loading}}),_c('v-main',[_c('v-container',{staticClass:"login-container",attrs:{"fluid":""}},[_c('v-row',{staticClass:"login-container__row",attrs:{"align-content":"center"}},[_c('v-col',{staticClass:"login-container__col hidden-sm-and-down",class:_vm.handlePublication() ? ' left__publication' : 'left__av',style:(!_vm.loading ? _vm.background : ''),attrs:{"cols":"12","md":"6"}},[(_vm.handlePublication())?_c('div',{staticClass:"publication__text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.publication.content)}})]):_c('div',{staticClass:"left__text"},[(!_vm.broker.id  && !_vm.loading)?_c('div',[_c('p',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.$t('login_knowledge')))]),_c('p',{staticClass:"uppercase"},[_vm._v(_vm._s(_vm.$t('login_&_reliability')))])]):_vm._e()])]),(!_vm.loading)?_c('v-col',{staticClass:"login-container__col right",attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","offset":"1"}},[_c('div',{staticClass:"logo-container d-flex flex-column justify-center align-center"},[_c('img',{class:{'logo-container__image': !_vm.broker.id},attrs:{"src":_vm.logo,"alt":_vm.altLogo}}),_c('p',{staticClass:"logo-container__text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])])]),_c('v-col',{attrs:{"cols":"10","offset":"1"}},[_c('v-card',{attrs:{"rounded":"","elevation":"0"}},[(_vm.questionSelectedQuantity == 0)?_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitCreateQuestionUser($event)}}},[_vm._l((_vm.userQuestions),function(question,i){return _c('v-row',{key:i,staticClass:"px-0 px-md-5"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('login_security_questions'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"item-text":"question","item-value":"id","items":_vm.items,"menu-props":{ maxHeight: '400' },"label":_vm.$t('login_security_questions'),"persistent-hint":"","clearable":"","error-messages":errors[0]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.question))+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(item.question).slice(0, 15) + '...')+" ")])]}}],null,true),model:{value:(question.id),callback:function ($$v) {_vm.$set(question, "id", $$v)},expression:"question.id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('login_security_answer')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Responda la pregunta brevemente","label":_vm.$t('login_security_answer'),"error-messages":errors[0],"type":_vm.show ? 'text' : 'password',"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-space-between flex-row align-baseline",attrs:{"cols":"12"}},[_c('v-spacer'),_c('language-menu',{staticClass:"mx-auto"})],1)],1)}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{class:{
                            'login-button': !_vm.broker.id
                          },attrs:{"color":"primary","type":"submit","rounded":"","loading":_vm.loading,"disabled":invalid}},[_vm._v(_vm._s(_vm.$t(_vm.saveText)))])],1)],2)]}}],null,false,3679711176)})],1):_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitValidateQuestionUser($event)}}},[_vm._l((_vm.userQuestions),function(question,i){return _c('v-row',{key:i,staticClass:"px-0 px-md-5"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t(question.question)))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('login_security_answer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('login_security_answer'),"placeholder":_vm.$t('login_security_answer_placeholder'),"error-messages":errors[0],"autocapitalize":"none","type":_vm.show ? 'text' : 'password',"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(question.answer),callback:function ($$v) {_vm.$set(question, "answer", $$v)},expression:"question.answer"}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-space-between flex-row align-baseline",attrs:{"cols":"12"}},[_c('v-spacer'),_c('language-menu',{staticClass:"mx-auto"})],1)],1)}),_c('v-row',{staticClass:"d-flex flex-column-reverse flex-md-row justify-center align-center",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"btn-cancel my-2",attrs:{"outlined":"","color":"primary","to":{ name: 'Login', query: { broker: _vm.$route.query.broker } },"rounded":""}},[_vm._v(_vm._s(_vm.$t('btn_cancel')))])],1),_c('v-col',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{staticClass:"my-2",class:{
                              'login-button': !_vm.broker.id
                            },attrs:{"color":"primary","type":"submit","rounded":"","loading":_vm.loading,"disabled":invalid}},[_vm._v(_vm._s(_vm.$t(_vm.saveText)))])],1)],1)],2)]}}],null,false,2912442998)})],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }